import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
const $ = require("jquery");
$.Datatable = require("datatables.net");
export default function HoldUser() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }


  let [limit1, setLimit1] = useState(10);

  const setpageLimit1 = (event) => {
    let key = event.target.value;
    setLimit1(key);
  };

  const [pageNumber1, setPageNumber1] = useState(0);

  const [numberOfPages1, setNumberOfPages1] = useState(0);


  const handlePageClick1 = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber1(currentPage);
    // scroll to the top
    //window.scrollTo(0, 0)
  };


 
  const [user1, setUser1] = useState(false);
  const getHold = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .get(
        baseUrl +
          `lowest_hold?page=${pageNumber1}&_limit=${limit1}`,
        { headers }
      )
      .then((res) => {
          let users = res.data.admin;
          let sortedUsers = users.sort((a, b) => {
      if (new Date(a.createdAt) > new Date(b.createdAt)) {
        return -1;
      } else if (new Date(a.createdAt) < new Date(b.createdAt)) {
        return 1;
      } else {
        return 0;
      }
    });
    
        setUser1(sortedUsers);
        setNumberOfPages1(res.data.totalPages);
        // $('table').dataTable();
      })
      .catch((e) => alert(e));
  };
  const blockPlayer = (player) => {
    const confirmBox = window.confirm(`are you sure block ${player.Name}`);
    if (confirmBox === true) {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      const userType = player.user_type == "Block" ? "User" : "Block";
      axios
        .post(
          baseUrl + `block/user/${player._id}`,
          { user_type: userType },
          { headers }
        )
        .then((res) => {
          getHold(res.data);
          console.log(res.data);
        });
    }
  };
  const deletePlayer = (player) => {
    const confirmBox = window.confirm(`are you sure delete ${player.Name}`);
    if (confirmBox === true) {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      const userType = player.user_type == "Delete";
      axios
        .post(
          baseUrl + `delete/user/${player._id}`,
          { user_type: userType },
          { headers }
        )
        .then((res) => {
            getHold(res.data);
          console.log(res.data);
        });
    }
  };


  useEffect(()=>{
getHold()
  },[pageNumber1,limit1])

  return (
    <>

 {   user1 && (
      <div>
        
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card text-white">
              <div
                className="card-body text-light"
                style={{ backgroundColor: "rgba(0, 27, 11, 0.734)" }}
              >
                <h4 className="card-title">Users List</h4>

                {/* searching */}
                <div className="row">
                 

                  <select
                    className="form-control col-sm-1 m-1 bg-dark text-light"
                    id="pagelimit"
                    name="pagelimit"
                    onChange={setpageLimit1}
                  >
                    <option value="10">Set limit</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>

                <div className="table-responsive">
                  <table className="table text-light">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th> ID</th>
                        <th> Name</th>
                        <th> Mobile </th>
                        <th> Balance </th>
                        <th> MissMatch</th>
                        <th>Game hold</th>
                        <th> Reffered By </th>
                        <th> Registered On </th>
                        <th> Verified </th>
                        <th> Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {user1.map((user, key) => (
                        <tr key={user._id}>
                          <td>{key + 1}</td>
                          <td>{user._id}</td>
                          <td>
                            <span className="pl-2 text-primary">
                              {user.Name}
                            </span>
                          </td>
                          <td className="text-success">
                            <a
                              className="cxy flex-column"
                              href={`https://api.whatsapp.com/send?phone=+91${user.Phone}&text=Hello`}
                            >
                              {user.Phone}
                            </a>
                          </td>
                          <td>₹{user.Wallet_balance}</td>

                          <td
                            className={`${
                              user.Wallet_balance -
                                (user.wonAmount +
                                  user.totalDeposit +
                                  user.referral_earning +
                                  user.totalBonus -
                                  (user.loseAmount +
                                    user.totalWithdrawl +
                                    user.referral_wallet +
                                    user.withdraw_holdbalance +
                                    user.hold_balance +
                                    user.totalPenalty)) !=
                              0
                                ? "text-danger"
                                : "text-success"
                            }`}
                          >
                            ₹{" "}
                            {user.Wallet_balance -
                              (user.wonAmount +
                                user.totalDeposit +
                                user.referral_earning +
                                user.totalBonus -
                                (user.loseAmount +
                                  user.totalWithdrawl +
                                  user.referral_wallet +
                                  user.withdraw_holdbalance +
                                  user.hold_balance +
                                  user.totalPenalty))}
                          </td>
                          <td>{user.hold_balance}</td>
                          <td className="text-success">{user.referral}</td>
                           <td className="text-success">{user.createdAt}</td>
                          <td>
                            <div className="badge badge-outline-success">
                              {user.verified}
                            </div>
                          </td>
                          <td>
                            <button
                              type="button"
                              className={`btn  mx-1 ${
                                user.user_type == "Block"
                                  ? "btn-success"
                                  : "btn-danger"
                              }`}
                              onClick={() => {
                                blockPlayer(user);
                              }}
                            >
                              {user.user_type == "Block" ? "Unblock" : "Block"}
                            </button>
                            <Link
                              type="button"
                              className="btn btn-info mx-1"
                              to={`/user/adduser/${user._id}`}
                            >
                              Edit User
                            </Link>
                            <Link
                              type="button"
                              className="btn btn-primary mx-1"
                              to={`/user/view_user/${user._id}`}
                            >
                              View
                            </Link>
                            <button
                              type="button"
                              className={`btn  mx-1 ${
                                user.user_type == "Block"
                                  ? "btn-success"
                                  : "btn-danger"
                              }`}
                              onClick={() => {
                                deletePlayer(user);
                              }}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="mt-4">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={numberOfPages1}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick1}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    </>
  );
}
